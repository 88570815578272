<template>
  <div class="d-flex-column align-center main">
    <el-form label-position="top" class="main full-width input-underline">
      <el-card v-if="inRegoFlow">
        <div class="mw-400 main">
          <h3 class="mt-0">Pending Verification</h3>
          <p>
            Please upload supporting identification documents of the participant for approval by
            your Club/League below.
            <span class="sm-txt"
              >(Once participant's DOB is verified by the entity the document will be removed from
              the NRL database)</span
            >
          </p>
          <SuperInput
            v-model="form.idType.value"
            :field="form.idType"
            :errors.sync="form.idType.errors"
          />
          <vue-upload-component
            v-if="form.idType.value"
            ref="uploadDocument"
            class="upload-component"
            v-model="uploads"
            inputId="documentUpload"
            :multiple="true"
            :drop="true"
            :post-action="docUploadURL"
            :headers="uploadHeaders"
            :data="memberData"
            @input-file="inputFileVerification"
            @input-filter="inputFilterVerification"
          >
            <div class="upload-box">
              <a href="#">
                <i class="el-icon-upload"></i>
                <br />
                Upload a document
              </a>
            </div>
          </vue-upload-component>

          <el-table v-if="uploads.length !== 0" :data="uploads" style="width: 100%">
            <el-table-column prop="data.doctype" label="Type of ID"> </el-table-column>
            <el-table-column prop="name" label="File Name"> </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <button
                  @click.prevent="deleteRow(scope.$index, uploads)"
                  class="button-invisible delete"
                >
                  Delete
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <div v-else class="mw-400 main">
        <p>
          Please upload supporting identification documents of the participant for approval by your
          Club/League below.
          <span class="sm-txt"
            >(Once participant's DOB is verified by the entity the document will be removed from the
            NRL database)</span
          >
        </p>
        <SuperInput
          v-model="form.idType.value"
          :field="form.idType"
          :errors.sync="form.idType.errors"
        />
        <vue-upload-component
          v-if="form.idType.value"
          ref="uploadDocument"
          class="upload-component"
          v-model="uploads"
          inputId="documentUpload"
          :multiple="true"
          :drop="true"
          :post-action="docUploadURL"
          :headers="uploadHeaders"
          :data="memberData"
          @input-file="inputFileVerification"
          @input-filter="inputFilterVerification"
        >
          <div class="upload-box">
            <a href="#">
              <i class="el-icon-upload"></i>
              <br />
              Upload a document
            </a>
          </div>
        </vue-upload-component>

        <el-table v-if="uploads.length !== 0" :data="uploads" style="width: 100%">
          <el-table-column prop="data.doctype" label="Type of ID"> </el-table-column>
          <el-table-column prop="name" label="File Name"> </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <button
                @click.prevent="deleteRow(scope.$index, uploads)"
                class="button-invisible delete"
              >
                Delete
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>

    <div class="d-flex-column main mw-400">
      <div class="align-text-center">
        <el-button type="primary" class="full-width" @click.stop.prevent="next">
          {{ uploads.length === 0 ? "Skip" : "Upload" }}
        </el-button>
      </div>
      <div v-if="inRegoFlow" class="align-text-center">
        <el-button class="full-width button-invisible" @click="back">Back</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import VueUploadComponent from "vue-upload-component";
import { makeForm } from "@/utils/forms";
import { idTypes, docUploadURL } from "@/utils/constants";
import SuperInput from "@/components/form/fields/SuperInput.vue";

export default {
  name: "verificationupload",
  components: { SuperInput, VueUploadComponent },
  data() {
    return {
      form: makeForm({
        idType: {
          label: "Type of ID being uploaded",
          options: idTypes.map((o) => ({ label: o.name, value: o.type })),
          component: "SelectSP",
        },
      }),
      uploads: [],
      docUploadURL,
    };
  },
  props: {
    inRegoFlow: Boolean,
  },
  methods: {
    ...mapActions("registration", ["submit"]),
    ...mapMutations("registration", ["UPDATE_DOCUMENTS"]),
    ...mapMutations("views", ["PUSH_NOTIFICATION"]),
    ...mapMutations("root", ["LOADING"]),
    async next() {
      if (this.uploads.length !== 0) {
        // Uploading...
        this.LOADING(true);
        this.$refs.uploadDocument.active = true;
      } else if (this.inRegoFlow) {
        // Rego flow - Next Screen
        await this.submit();
        this.$router.push({ name: "regocart" });
      } else {
        // Edit Profile - Modal
        this.$emit("closeModal");
        this.PUSH_NOTIFICATION({
          msg: "Document uploaded successfully",
          type: "success",
        });
        window.scrollTo(0, 0);
      }
    },
    back() {
      if (this.getAvailableProductsRealConcat.length > 0) {
        // Check for addons
        this.$router.push({ name: "chooseaddon" });
      } else {
        // Redirect to Profile if no addons found
        this.$router.push({ name: "participantdetails" });
      }
    },
    inputFileVerification(newFile, oldFile, prevent) {
      if (newFile && !this.$refs.uploadDocument.active) {
        // Adding doctype to new files
        const type = idTypes.find((t) => t.type === this.form.idType.value);
        if (!type) {
          this.PUSH_NOTIFICATION({
            msg: "Please select a document type",
            type: "warning",
          });
          return prevent();
        }
        const { name } = type;
        const { inRegoFlow } = this;
        // eslint-disable-next-line no-param-reassign
        newFile.data.doctype = name;
        // eslint-disable-next-line no-param-reassign
        newFile.data.inRegoFlow = inRegoFlow;
      }

      if (newFile && newFile.error) {
        // Error Uploading
        const { doctype } = newFile.data;
        this.LOADING(false);
        this.PUSH_NOTIFICATION({
          msg: `${doctype} upload has failed`,
          type: "warning",
        });
        this.uploads = this.uploads.filter((file) => file.id !== newFile.id);
      } else if (!this.uploads.map((upload) => upload.success).includes(false)) {
        // Upload Success
        this.LOADING(false);
        this.uploads = [];
        if (newFile && newFile.response) {
          this.next(newFile.response.documents);
        }
      }
      return true;
    },
    inputFilterVerification(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg|pdf)$/i.test(newFile.name)) {
        this.PUSH_NOTIFICATION({
          msg: "Please submit a valid file type (png/jpg/jpeg/pdf)",
          type: "warning",
        });
        return prevent();
      }
      return true;
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters("user", ["uploadHeaders"]),
    ...mapGetters("registration", ["getCurrentMemberId", "getAvailableProductsRealConcat"]),
    memberData() {
      return {
        _id: this.getCurrentMemberId,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-component {
  width: 100%;
  .upload-box {
    display: flex;
    width: 100%;
    height: 10rem;
    border: 2px dashed $secondary;
    border-radius: 3px;
    cursor: pointer;

    a {
      text-align: center;
      color: $secondary;
      margin: auto;
      text-decoration: none;

      i {
        font-size: 2rem;
      }
    }
  }
}

.sm-txt {
  font-size: 0.75rem;
}

.delete {
  width: 100%;
  text-align: right;
  color: red;
  font-size: 14px;
  cursor: pointer;
}
</style>
