<template>
<div class="overlay">
  <div class="modal d-flex-column">
    <div class="header d-flex flex-0">
      <div class="title flex-1">{{title}}</div>
      <button class="close flex-0 button-no-style" @click="close">
        <i class="el-icon-close" aria-label="Close pop-up"></i>
      </button>
    </div>
    <div class="scroll-container flex-1">
      <slot/>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },

};
</script>

<style lang="scss" scoped>
.overlay {
  z-index: 999;
  position: fixed;
  background-color: rgba(#000000, 0.4);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.modal {
  position: fixed;
  background-color: $white;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(#000000, 0.2);

  @media (min-width: $sm) {
    top: 10%;
    left: 20%;
    height: 80%;
    width: 60%;
  }

  @media (min-width: $md) {
    top: 20%;
    left: 20%;
    height: 60%;
    width: 60%;
  }

  .header {
    background-color: $primary;


    .title {
      padding: 15px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    }

    .close {
      padding: 5px;
      cursor: pointer;

      i {
        font-size: 2rem;
        color: $white;
      }
    }
  }

  .scroll-container {
    overflow-y: scroll;
  }
}
</style>
